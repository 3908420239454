<!--
 * @Author: your name
 * @Date: 2022-04-13 10:31:14
 * @LastEditTime: 2022-04-18 15:01:53
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /seller-enter-web/src/components/operationLog/index.vue
-->

<template>
  <div class='JZContainer'>
    <!-- :leftToolbar="tableButtons" -->
    <TableWithPagination
      :tableData="currentLogLists"
      class="product_list"
      :columnList="columnlogLists"
      :sortInfo.sync="createTimeSortInfo"
      :pagination.sync="pagination"
      @size-change="getOperationLogLists"
      @current-change="getOperationLogLists"
      @sort-change="getOperationLogLists"
      >
    </TableWithPagination>
    <el-dialog
        title="添加操作日志"
        :visible.sync="dialogFormVisible"
        width="50%"
        :append-to-body='$store.state.common.appendToBody'
        :destroyOnClose='$store.state.common.destroyOnClose'
        :close-on-click-modal='$store.state.common.closeOnClickModal'
      >
      <div class="content">
        <el-form
          :model="optionLogForm"
          :rules="optionLogFormRules"
          ref="optionLogForm"
          label-width="100px"
          class="content_block"
        >
          <el-form-item label="事件类型：" prop="type">
            <el-select
              v-model.trim="optionLogForm.type"
              placeholder="请选择事件类型"
            >
              <el-option
                v-for="item in operationTypeOptions"
                :key="item.label"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="操作内容：" prop="content">
            <el-input
              v-model.trim="optionLogForm.content"
              clearable
              placeholder="请填写操作内容"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer " style="text-align:right;" >
          <el-button @click="dialogFormVisible=false" size="mini">取 消</el-button>
          <el-button type="primary" @click="saveOptionLog" size="mini">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TableWithPagination, { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { AddOperationLog, getOperationLogLists } from '@/api/accountStatement';
export default {
  name: 'OperationLog',
  props: {
    noPaddingTitle: {
      type: Boolean,
      default () {
        return false;
      },
    },
    vOperationAuth: {
      type: String,
      required: false,
    },
  },
  components: {
    TableWithPagination,
  },
  mixins: [TablePaginationMixin],
  data () {
    return {
      tableButtons: [
        {
          name: '添加日志',
          status: 'primary',
          size: 'small',
          code: 'Add',
          icon: 'vxe-icon--plus',
          permission: this.vOperationAuth,
          click: this.addNewLog,
        },
      ],
      detailInfo: {},
      currentLogLists: [],
      dialogFormVisible: false,
      optionLogForm: {
        type: '创建对账单',
        content: '',
      },
      // 2021年7月1日14:00:00 时间类型更新——>需求要求变更人 张有喜&董亚飞
      operationTypeOptions: [
        {
          label: '创建对账单',
        },
        {
          label: '对账单确认',
        },
        {
          label: '账单修改',
        },
        {
          label: '录入发票',
        },
        {
          label: '发票审核',
        },
        {
          label: '账单完成',
        },
      ],
      optionLogFormRules: {
        type: [
          { required: true, message: '请选择事件类型', trigger: 'change' },
        ],
        content: [
          { required: true, message: '请输入操作内容', trigger: 'blur' },
        ],
      },
      columnlogLists: [
        {
          tooltip: true,
          label: '操作人',
          minWidth: '120px',
          align: 'center',
          width: '',
          prop: 'userName',
        },
        {
          tooltip: true,
          label: '操作来源',
          minWidth: '120px',
          align: 'center',
          width: '',
          prop: 'source',
        },
        {
          tooltip: true,
          label: '事件类型',
          minWidth: '100px',
          prop: 'type',
        },
        {
          tooltip: true,
          label: '操作内容',
          minWidth: '110px',
          width: '',
          prop: 'content',
        },
        {
          tooltip: true,
          label: '操作时间',
          minWidth: '100px',
          width: '',
          sortable: false,
          prop: 'time',
          format: this.$options.filters['date-format'],
        },
      ],
    };
  },
  methods: {
    addNewLog () {
      this.optionLogForm.content = '';
      this.dialogFormVisible = true;
    },
    getCurrentParams () {
      let currentParams = {
        relationId: this.$route.query.id,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        types: [9],
      };
      return currentParams;
    },
    getOperationLogLists () {
      let currentParams = this.getCurrentParams();
      getOperationLogLists(currentParams).then(this.getOperationLogListsSuccessFun);
    },
    getOperationLogListsSuccessFun (jsonData) {
      let currentLists = {};
      if (jsonData.heads.code === 200) {
        currentLists = jsonData.body.list || [];
      } else {
        this.$JZShowAlert(jsonData.heads.message, 'error');
      }
      this.currentLogLists = currentLists;
      this.pagination.total = jsonData.body.total;
    },
    saveOptionLog () {
      this.$refs.optionLogForm.validate(valid => {
        if (valid) {
          AddOperationLog({
            relationId: this.$route.query.id,
            ...this.optionLogForm,
            source: '人工定义',
            logType: 9,
          }).then(this.saveOptionLogSuccessFun);
        }
      });
    },
    saveOptionLogSuccessFun (jsonData) {
      this.dialogFormVisible = false;
      if (jsonData.heads.code === 200) {
        this.getOperationLogLists();
      } else {
        this.$JZShowAlert(jsonData.heads.message, 'error');
      }
    },
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
/deep/ .title {
  width: 100%;
  padding-left: 10px;
  font-size: 14px;
  color: #333333;
  margin-top: 10px;
  margin-bottom: 10px;
  b {
    height:30px;
    border-left: 6px #02a7f0 solid;
    margin-right: 10px;
  }
}
.isHasPadding{
  margin-left: 0!important;
}
</style>
