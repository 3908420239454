<!--
 * @Author: your name
 * @Date: 2022-04-11 14:28:10
 * @LastEditTime: 2022-04-11 14:28:19
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /seller-enter-web/src/views/salesReconciliation/accountStatement/components/billingInformation.vue
-->
<template>
  <div class="container">
    <div class="infoTop" v-if="total">
      合计开票金额：{{total.toFixed(2)}}元
    </div>
    <TableWithPagination
      max-height='400px'
      :tableData="targetLists"
      :columnList="columnList"
    ></TableWithPagination>
  </div>
</template>

<script>
import TableWithPagination, {
  TablePaginationMixin,
} from '@/components/common/TableWithPagination';
import { getReconciliationInvoiceList } from '@/api/accountStatement';
export default {
  name: 'BillingInformation',
  mixins: [TablePaginationMixin],
  components: {
    TableWithPagination,
  },
  methods: {
    initPage () {
      let currentParams = {
        id: this.$route.query.id,
      };
      getReconciliationInvoiceList(currentParams).then(({body}) => {
        let currentLists = body || [];
        let total = 0;
        if (currentLists && currentLists.length) {
          currentLists.forEach(item => {
            total += item.pureAmount || 0;
          });
        }
        total = total || 0;
        this.targetLists = currentLists;
        this.total = total;
      });
    },
  },
  computed: {
    columnList () {
      let status = this.$route.query.status;
      let currentLists = [
        {
          tooltip: true,
          label: '发票类型',
          minWidth: '150px',
          prop: 'invoiceTypeStr',
        },
        {
          tooltip: true,
          label: '发票号码',
          minWidth: '150px',
          prop: 'invoiceCode',
        },
        {
          tooltip: true,
          label: 'sku',
          minWidth: '180px',
          prop: 'skuId',
        },
        {
          tooltip: true,
          label: '商品名称',
          minWidth: '150px',
          prop: 'goodsName',
        },
        {
          tooltip: true,
          label: '规格型号',
          minWidth: '150px',
          prop: 'spec',
        },
        {
          tooltip: true,
          label: '品牌名称',
          minWidth: '150px',
          prop: 'brandName',
        },
        {
          tooltip: true,
          label: '无税金额',
          minWidth: '100px',
          prop: 'pureAmount',
        },
        {
          tooltip: true,
          label: '税额',
          minWidth: '150px',
          prop: 'taxAmount',
        },
        {
          tooltip: true,
          label: '价格合计',
          minWidth: '150px',
          prop: 'totalAmount',
        },
        {
          tooltip: true,
          label: '开票日期',
          minWidth: '150px',
          prop: 'invoiceDate',
        },
        {
          tooltip: true,
          label: '开票人',
          minWidth: '100px',
          prop: 'createUserName',
        },
      ];
      if (['run', 'already'].includes(status)) {
        currentLists = [
          ...currentLists,
          {
            tooltip: true,
            label: '业务状态',
            minWidth: '150px',
            prop: 'businessStatusStr',
          },
          {
            tooltip: true,
            label: '收票状态',
            minWidth: '150px',
            prop: 'registerStatusStr',
          },
          {
            tooltip: true,
            label: '红蓝标志',
            minWidth: '150px',
            prop: 'redBlueStr',
          },
        ];
      } else if (['reject', 'wait'].includes(status)) {
        currentLists = [
          ...currentLists,
          {
            tooltip: true,
            label: '业务状态',
            minWidth: '150px',
            prop: 'businessStatusStr',
          },
          {
            tooltip: true,
            label: '红蓝标志',
            minWidth: '150px',
            prop: 'redBlueStr',
          },
          {
            tooltip: true,
            label: '记账状态',
            minWidth: '150px',
            prop: 'relationStatusStr',
          },
        ];
      }
      return currentLists;
    },
  },
  data () {
    return {
      total: 0,
      targetLists: [],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
/deep/ .infoTop{
  text-align: right;
  width: 100%;
  padding-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  box-sizing: border-box;
}
</style>
