<!--
 * @Author: your name
 * @Date: 2022-04-11 09:20:01
 * @LastEditTime: 2022-04-11 09:20:04
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /seller-enter-web/src/views/salesReconciliation/accountStatement/detail.vue
-->
<template>
  <div class='JZContainer'>
    <!-- 账单基本信息 -->
    <ContentBlock title="账单基本信息">
      <el-form label-width="120px" label-position="right">
      <el-row :gutter="20">
        <el-col :span="10">
            <el-row>
              <el-col :span="24">
                <el-form-item label="寄票联系人：" required>
                  {{JZFormInfo.ticketContactName || '--'}}
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="寄票电话：" required>
                  {{JZFormInfo.ticketPhone || '--'}}
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="寄票地址：" required>
                  {{JZFormInfo.provinceName || ''}}{{JZFormInfo.cityName || ''}}{{JZFormInfo.areaName || ''}}{{JZFormInfo.ticketAddress || '--'}}
                </el-form-item>
              </el-col>
            </el-row>
        </el-col>
        <el-col :span="14" v-if="status === '1'" >
            <el-row>
              <el-col :span="12">
                <el-form-item label="对账区间：" >
                  {{JZFormInfo.reconciliaStart || ''}} - {{JZFormInfo.reconciliaEnd || ''}}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="客户抬头：" >
                  {{JZFormInfo.unitName || '--'}}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="付款方式：" >
                  {{
                    {
                      'AP': '账期付款',
                    }[JZFormInfo.paymentWay]}}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="对账总额：" >
                  <span class="warning">{{JZFormInfo.reconciliaAmount}}</span>元
                </el-form-item>
              </el-col>
            </el-row>
        </el-col>
        <el-col :span="14" v-else >
            <el-row>
              <el-col :span="12" v-if="status==='5'">
                <el-form-item label="核算单位：" >
                  {{JZFormInfo.unitName || '--'}}
                </el-form-item>
              </el-col>
              <el-col :span="12" v-else>
                <el-form-item label="客户抬头：" >
                  {{JZFormInfo.unitName || '--'}}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="对账区间：" >
                 {{JZFormInfo.reconciliaStart || ''}} - {{JZFormInfo.reconciliaEnd || ''}}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="付款方式：" >
                  {{
                    {
                      'AP': '账期付款',
                    }[JZFormInfo.paymentWay]}}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="对账总额：" >
                  <span class="warning">{{JZFormInfo.reconciliaAmount}}</span>元
                </el-form-item>
              </el-col>
            </el-row>
        </el-col>
      </el-row>
      <el-row v-if="['1', '3'].includes(status)">
        <el-col :span="10">
          <el-form-item label="账单创建人：" >
            {{JZFormInfo.createUserName || '--'}}
          </el-form-item>
        </el-col>
        <el-col :span="14">
          <el-form-item label="对账单状态：" >
            {{
              {'wait': '待供应商确认',
              'reject': '供应商驳回',
              'run': '结算中',
              'already': '已完成',
            }[JZFormInfo.reconciliaState] || '--'}}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="status==='2'">
        <el-col :span="10">
          <el-form-item label="账单创建人：" >
             {{JZFormInfo.createUserName || '--'}}
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label-width="130px" label="账单创建人部门：" >
            {{JZFormInfo.createDeptName || '--'}}
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="对账单状态：" >
            {{
              {'wait': '待供应商确认',
              'reject': '供应商驳回',
              'run': '结算中',
              'already': '已完成',
            }[JZFormInfo.reconciliaState] || '--'}}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="status==='4'">
        <el-col :span="10">
          <el-form-item label="账单创建人：" >
            {{JZFormInfo.createUserName || '--'}}
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="对账单状态：" >
             {{
              {'wait': '待供应商确认',
              'reject': '供应商驳回',
              'run': '结算中',
              'already': '已完成',
            }[JZFormInfo.reconciliaState] || '--'}}
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="驳回原因：" >
            {{JZFormInfo.rejectReason || '--'}}
          </el-form-item>
        </el-col>
      </el-row>
      </el-form>
    </ContentBlock>
    <!-- 账单执行信息 -->
    <ContentBlock title="账单执行信息" v-if="status!=='1'">
      <el-form label-width="120px" label-position="right">
        <el-row :gutter="20">
          <el-col :span="6" class="borderRight">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="账单总金额：" >
                    {{JZFormInfo.reconciliaAmount}} 元
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="对账数量：" >
                    {{JZFormInfo.reconciliaNum}}
                  </el-form-item>
                </el-col>
              </el-row>
          </el-col>
          <el-col :span="12" class="borderRight">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="开票金额：" >
                    {{JZFormInfo.invoicedAmount}} 元
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="入票金额：" >
                    {{JZFormInfo.inTicketAmount}} 元
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="开票数量：" >
                    {{JZFormInfo.invoicedNum}}
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="入票数量：" >
                    {{JZFormInfo.inTicketNum}}
                  </el-form-item>
                </el-col>
              </el-row>
          </el-col>
          <el-col :span="6">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="已核销金额：" >
                    {{JZFormInfo.alreadyAmount}} 元
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="待核销金额：" >
                    {{JZFormInfo.waitAmount}} 元
                  </el-form-item>
                </el-col>
              </el-row>
          </el-col>
        </el-row>
      </el-form>
    </ContentBlock>
    <!-- 对账明细 -->
    <ContentBlock >
      <ContentTitle>
        对账明细 <el-tooltip class="item" effect="dark" content="已经形成对账单的明细无法多次对账" placement="top-start">
                <i class="el-icon-info" style="margin-left:10px;" />
              </el-tooltip>
      </ContentTitle>
      <TableWithPagination
        :leftToolbar="tableButtons"
        :columnList="columnList"
        :tableData="dataList"
        max-height='400px'
      />
    </ContentBlock>
    <ContentBlock v-if="$route.query.isWait!=='1'">
      <el-tabs v-model="activeName" @tab-click="handleClick" size='small'>
        <el-tab-pane label="操作日志" name="1">
          <OperationLog ref="operationLog"></OperationLog>
        </el-tab-pane>
        <el-tab-pane label="开票信息" name="2">
          <BillingInformation ref="billingInformation"></BillingInformation>
        </el-tab-pane>
        <el-tab-pane label="付款信息" name="3">
          <PaymentInformation ref="paymentInformation"></PaymentInformation>
        </el-tab-pane>
        <el-tab-pane label="核销信息" name="4">
          <WriteOffInformation ref="writeOffInformation"></WriteOffInformation>
        </el-tab-pane>
      </el-tabs>
    </ContentBlock>
    <BottomPanel v-if="$route.query.isWait==='1'" class="adviceContainer">
      <el-form label-width="100px" label-position="right" class="adviceForm">
        <el-form-item label="审核意见：" >
          <el-input placeholder="请输入审核意见" v-model='reason' maxlength="50" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <el-button type="default" @click="goBack">取消</el-button>
      <el-button type="danger" @click="handleReject">驳回</el-button>
      <el-button type="primary" @click="handleSubmit">确认通过</el-button>
    </BottomPanel>
    <BottomPanel v-else>
      <el-button type="default" @click="goBack">返回</el-button>
    </BottomPanel>
  </div>
</template>

<script>
import OperationLog from '@/components/operationLog/index.vue';
import BillingInformation from './components/billingInformation.vue';
import PaymentInformation from './components/paymentInformation.vue';
import WriteOffInformation from './components/writeOffInformation.vue';
import {exportTable, getDetailReconciliation, editReconciliationState} from '@/api/accountStatement';
import TableWithPagination, {
  TablePaginationMixin,
} from '@/components/common/TableWithPagination';
export default {
  name: '',
  mixins: [TablePaginationMixin],
  components: {
    TableWithPagination,
    OperationLog,
    BillingInformation,
    PaymentInformation,
    WriteOffInformation,
  },
  created () {
    this.initPage();
  },
  methods: {
    handleClick () {
      let value = this.activeName;
      if (value === '1') {
        this.$refs.operationLog.getOperationLogLists();
      } else if (value === '2') {
        this.$refs.billingInformation.initPage();
      } else if (value === '3') {
        this.$refs.paymentInformation.initPage();
      } else if (value === '4') {
        this.$refs.writeOffInformation.initPage();
      }
    },
    initPage () {
      this.getDetailInfo();
      if (this.status !== '1') {
        this.activeName = '1';
        this.$nextTick(() => {
          this.$refs.operationLog.getOperationLogLists();
        });
      }
    },
    getDetailInfo () {
      getDetailReconciliation({id: this.$route.query.id}).then(({body}) => {
        this.JZFormInfo = body;
        this.dataList = body.reconciliationList || [];
      });
    },
    exportTable () {
      let currentParams = {
        pageFlag: false,
        id: this.$route.query.id,
      };
      exportTable(currentParams).then(({data})=>{
        const downloadData = {
          data: data,
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          fileTitle: `对账明细_${Date.now()}`,
        };
        this.downBinaryStreamHandle(downloadData);
      });
    },
    downBinaryStreamHandle ({ data, type, fileTitle }) {
      let blob = new Blob([data], { type });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileTitle;
      document.querySelectorAll('body')[0].appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    handleReject () {
      // 驳回接口
      if (!this.reason) {
        this.$JZShowAlert('驳回时驳回原因不可为空！', 'error');
        return;
      }
      editReconciliationState({id: this.$route.query.id, state: 'reject', supIdea: this.reason}).then(() => {
        this.goBack();
      });
    },
    handleSubmit () {
      editReconciliationState({id: this.$route.query.id, state: 'run', supIdea: this.reason}).then(() => {
        this.goBack();
      });
    },
    goBack () {
      this.$router.go(-1);
    },
  },
  data () {
    return {
      reason: '',
      activeName: '1',
      JZFormInfo: {
      },
      dataList: [],
    };
  },
  computed: {
    tableButtons () {
      return [
        {
          name: '导出明细', // 按钮内容(必填)
          status: 'primary', // 按钮样式
          size: 'small', // 大小尺寸
          con: 'el-icon-printer', // 按钮内部icon
          code: 'exportTable', // code(必填)
          icon: '', // 按钮内部icon
          permission: '', // 权限码，若为空字符串或不设置则表示不进行权限控制
          click: this.exportTable, // 点击回调事件
        },
      ];
    },
    columnList () {
      let status = this.$route.query.status;
      let billTypecolumn = {
        tooltip: true,
        label: '单据类型',
        minWidth: '110px',
        prop: 'billType',
        render: (h, {row}) => {
          return h('div', row.billType === 'IN' ? '采购入库' : '采购退货出库');
        },
      };
      let indexColumn = {
        tooltip: true,
        label: '行号',
        minWidth: '70px',
        prop: 'Index',
        render: (h, {index}) => {
          let currentIndex = index + 1 + (this.pagination.currentPage - 1) * this.pagination.pageSize;
          return h('div', currentIndex);
        },
      };
      let salesmanName = [
        {
          tooltip: true,
          label: status === 'reject' ? '销售单号' : '采购单号',
          minWidth: '150px',
          prop: 'poCode',
        },
        {
          tooltip: true,
          label: 'sku编码',
          minWidth: '180px',
          prop: 'skuCode',
        },
        {
          tooltip: true,
          label: '收货信息',
          minWidth: '220px',
          prop: 'receivingName',
          render: (h, {row}) => {
            let msg = `${row.receivingName ? row.receivingName + '-' : ''}${
              row.receivingPhone ? row.receivingPhone + '-' : ''
            }${row.receivingProvinceName || ''}${row.receivingCityName || ''}${
              row.receivingAreaName || ''
            }${row.receivingAddrs || '--'}`;
            return h('span', msg);
          },
        },
        {
          tooltip: true,
          label: '关联仓库',
          minWidth: '110px',
          prop: 'warehouseName',
        },
        {
          tooltip: true,
          label: status === 'wait' ? '销售合同号' : '销售合同号',
          minWidth: '150px',
          prop: 'pcCode',
        },
        {
          tooltip: true,
          label: '采购合同备注',
          minWidth: '150px',
          prop: 'contractRemark',
        },
        {
          tooltip: true,
          label: '业务员',
          minWidth: '150px',
          prop: 'salesmanName',
        },
        {
          tooltip: true,
          label: '业务员部门',
          minWidth: '150px',
          prop: 'salesmanDeptName',
        },
        {
          tooltip: true,
          label: '订单税率',
          minWidth: '150px',
          prop: 'taxRate',
          format: val => `${val}%`,
        },
        {
          tooltip: true,
          label: '付款方式',
          minWidth: '110px',
          prop: 'paymentWay',
          render: (h, {row}) => {
            let statusStr = {
              'AP': '账期付款',
            }[row.paymentWay];
            return h('span', statusStr);
          },
        },
      ];
      let goodsColumn = [
        {
          tooltip: true,
          label: '商品名称',
          minWidth: '180px',
          prop: 'goodsName',
        },
        {
          tooltip: true,
          label: '规格',
          minWidth: 130,
          prop: 'specification',
        },
        {
          tooltip: true,
          label: '型号',
          minWidth: 130,
          prop: 'model',
        },
        {
          tooltip: true,
          label: '厂家编码',
          prop: 'factoryCode',
          minWidth: 130,
        },
      ];
      let unitOfMsmentColumn = {
        tooltip: true,
        label: '计量单位',
        minWidth: '150px',
        prop: 'unitOfMsment',
      };
      let stockDateColumn = {
        tooltip: true,
        label: '出入库日期',
        minWidth: '180px',
        prop: 'stockDate',
      };
      let stockNumColumn = {
        tooltip: true,
        label: '出入库数量',
        minWidth: '110px',
        prop: 'stockNum',
      };
      let stockCodeColumn = {
        tooltip: true,
        label: '出入库单号',
        minWidth: '130px',
        prop: 'stockCode',
      };
      let stockAmountColumn = {
        tooltip: true,
        label: '出入库金额',
        minWidth: '110px',
        prop: 'stockAmount',
      };
      let invoicedNumColumn = {
        tooltip: true,
        label: '开票数量',
        minWidth: '110px',
        prop: 'invoicedNum',
      };
      let waitInvoicedNumColumn = {
        tooltip: true,
        label: '待开票数量',
        minWidth: '110px',
        prop: 'waitInvoicedNum',
      };
      let writeOffNumColumn = {
        tooltip: true,
        label: '开票冲销数量',
        minWidth: '110px',
        prop: 'writeOffNum',
      };
      let inTicketNumColumn = {
        tooltip: true,
        label: '入票数量',
        minWidth: '110px',
        prop: 'inTicketNum',
      };
      let inTicketAmountColumn = {
        tooltip: true,
        label: '入票金额',
        minWidth: '110px',
        prop: 'inTicketAmount',
      };
      let currentLists = [];
      if (this.$route.query.isWait) {
        currentLists = [
          indexColumn,
          billTypecolumn,
          ...goodsColumn,
          unitOfMsmentColumn,
          stockDateColumn,
          stockNumColumn,
          waitInvoicedNumColumn,
          writeOffNumColumn,
          {
            tooltip: true,
            label: '订单单价',
            minWidth: '110px',
            prop: 'orderPrice',
          },
          stockAmountColumn,
          {
            tooltip: true,
            label: '订单税率',
            minWidth: '110px',
            prop: 'taxRate',
            format: val => `${val}%`,
          },
          stockCodeColumn,
          {
            tooltip: true,
            label: '采购单号',
            minWidth: '150px',
            prop: 'poCode',
          },
          {
            tooltip: true,
            label: 'sku编码',
            minWidth: '180px',
            prop: 'skuCode',
          },
          {
            tooltip: true,
            label: '关联仓库',
            minWidth: '150px',
            prop: 'warehouseName',
          },
          {
            tooltip: true,
            label: '销售合同号',
            minWidth: '150px',
            prop: 'pcCode',
          },
        ];
      } else if (status === 'run') {
        currentLists = [
          indexColumn,
          stockDateColumn,
          ...goodsColumn,
          {
            tooltip: true,
            label: '销售单价',
            minWidth: '110px',
            prop: 'orderPrice',
          },
          unitOfMsmentColumn,
          stockNumColumn,
          invoicedNumColumn,
          waitInvoicedNumColumn,
          inTicketNumColumn,
          {
            tooltip: true,
            label: '开票金额',
            minWidth: '110px',
            prop: 'invoicedAmount',
          },
          inTicketAmountColumn,
          stockAmountColumn,
          {
            tooltip: true,
            label: '核销数量',
            minWidth: '110px',
            prop: 'invoicedNum',
          },
          stockAmountColumn,
          {
            tooltip: true,
            label: '核销金额',
            minWidth: '110px',
            prop: 'alreadyAmount',
          },
          {
            tooltip: true,
            label: '未核销金额',
            minWidth: '110px',
            prop: 'waitAmount',
          },
          billTypecolumn,
          stockCodeColumn,
          ...salesmanName,
          {
            tooltip: true,
            label: '已冲销开票数量',
            minWidth: '150px',
            prop: 'writeOffNum',
          },
        ];
      } else if (status === 'already') {
        currentLists = [
          indexColumn,
          stockDateColumn,
          billTypecolumn,
          ...goodsColumn,
          {
            tooltip: true,
            label: '销售单价',
            minWidth: '110px',
            prop: 'orderPrice',
          },
          unitOfMsmentColumn,
          stockNumColumn,
          inTicketNumColumn,
          inTicketAmountColumn,
          {
            tooltip: true,
            label: '核销数量',
            minWidth: '110px',
            prop: 'invoicedNum',
          },
          {
            tooltip: true,
            label: '商品金额',
            minWidth: '110px',
            prop: 'goodsAmount',
          },
          stockAmountColumn,
          stockCodeColumn,
          ...salesmanName,
          {
            tooltip: true,
            label: '客户抬头',
            minWidth: '180px',
            prop: 'unitName',
            format: () => this.JZFormInfo.unitName || '--',
          },
          {
            tooltip: true,
            label: '已冲销开票数量',
            minWidth: '150px',
            prop: 'writeOffNum',
          },
        ];
      } else if (status === 'reject') {
        currentLists = [
          indexColumn,
          stockDateColumn,
          billTypecolumn,
          ...goodsColumn,
          {
            tooltip: true,
            label: '销售单价',
            minWidth: '110px',
            prop: 'orderPrice',
          },
          unitOfMsmentColumn,
          stockNumColumn,
          invoicedNumColumn,
          {
            tooltip: true,
            label: '冲销开票数量',
            minWidth: '110px',
            prop: 'writeOffNum',
          },
          waitInvoicedNumColumn,
          inTicketNumColumn,
          inTicketAmountColumn,
          {
            tooltip: true,
            label: '商品金额',
            minWidth: '110px',
            prop: 'goodsAmount',
          },
          stockCodeColumn,
          ...salesmanName,
        ];
      } else if (status === 'wait') {
        currentLists = [
          indexColumn,
          stockDateColumn,
          billTypecolumn,
          ...goodsColumn,
          {
            tooltip: true,
            label: '销售单价',
            minWidth: '110px',
            prop: 'orderPrice',
          },
          unitOfMsmentColumn,
          stockNumColumn,
          invoicedNumColumn,
          {
            tooltip: true,
            label: '冲销开票数量',
            minWidth: '110px',
            prop: 'writeOffNum',
          },
          waitInvoicedNumColumn,
          inTicketNumColumn,
          inTicketAmountColumn,
          {
            tooltip: true,
            label: '商品金额',
            minWidth: '110px',
            prop: 'goodsAmount',
          },
          stockCodeColumn,
          ...salesmanName,
        ];
      }
      return currentLists;
    },
    status () {
      let status = ['', 'wait', 'run', 'already', 'reject'].indexOf(this.$route.query.status) + '';
      return status;
    },
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
/deep/ .borderRight{
  border-right: 1px solid #999999;
}
/deep/ .adviceContainer{
  display: flex;
  .adviceForm{
    flex: 1;
    margin-right: 20px;
    .el-form-item{
      margin-bottom: 0;
    }
  }
}
/deep/ .warning {
  color: #EC808D;
  font-size: 16px;
  margin-right: 5px;
}
/deep/.el-form-item--mini.el-form-item, /deep/ .el-form-item--small.el-form-item{
  margin-bottom: 1px;
}
</style>
